<template>
    <a href="javascript:void(0)" class="item" @click="$router.push({path: '/shop/detail', query: {id: item.id}})">
        <div class="image">
            <el-image :src="imageURL + item.imageUrl" fit="contain"></el-image>
        </div>


        <span class="price">¥{{item.price}}</span>

        <span class="name">{{item.name}}</span>

        <div class="info">
            <span class="text">
<!--                <span>{{item.salesSum ? item.salesSum : 0}}</span> 笔成交-->
            </span>
            <span class="text">{{item.storeName}}</span>
        </div>

<!--        <el-button type="primary" plain><img src="../../../assets/img/goods_det.png" />商品详情</el-button>-->
    </a>
</template>

<script>
  import {imageURL} from "../../../utils/config";

  export default {
    name: "goodsItem",
    props: {
      item: {
        type: Object,
        default: () => {
          return {}
        }
      }
    },
    data () {
      return {
        imageURL: imageURL,
      }
    },
    methods: {
    },
    components: {}
  }
</script>
